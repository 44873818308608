import React from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

const AddBlogs = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleCourse = (data) => {
    saveData(
      data.title,
      data.subject,
      data.category,
      data.tag,
      data.para1,
      data.para2,
      data.para3,
      data.para4,
      data.para5,
      data.para6,
      data.para7,
      data.para8,
      data.para9,
      data.para10,
      data.para11,
      data.para12,
      data.photoURL1,
      data.photoURL2
    );
  };

  const saveData = (
    title,
    subject,
    category,
    tag,
    para1,
    para2,
    para3,
    para4,
    para5,
    para6,
    para7,
    para8,
    para9,
    para10,
    para11,
    para12,
    photoURL1,
    photoURL2
  ) => {
    const user = {
      title,
      subject,
      category,
      tag,
      para1,
      para2,
      para3,
      para4,
      para5,
      para6,
      para7,
      para8,
      para9,
      para10,
      para11,
      para12,
      photoURL1,
      photoURL2,
    };
    fetch("https://server.arifur.xyz/postblog", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(user),
    })
      .then((res) => res.json())
      .then((data) => {
        toast.success("Data Inserted Successfuly!");
        reset();
      });
  };

  return (
    <div>
      <div className="py-4 lg:py-8">
        <div className="">
          <div onSubmit={handleSubmit} className="card w-full">
            <h2 className="text-center text-2xl font-semibold">Add a Blog</h2>
            <form onSubmit={handleSubmit(handleCourse)}>
              <div className="grid grid-cols-1 md:grid-cols-4 gap-3">
                <div className="form-control w-full mt-5">
                  <input
                    type="text"
                    {...register("title")}
                    className="input input-bordered w-full "
                    placeholder="Blog Title"
                  />
                </div>
                <div className="form-control w-full mt-5">
                  <input
                    type="text"
                    {...register("subject")}
                    className="input input-bordered w-full "
                    placeholder="Subject"
                  />
                </div>
                <div className="form-control w-full mt-5">
                  <input
                    type="text"
                    {...register("category")}
                    className="input input-bordered w-full "
                    placeholder="Blog Category"
                  />
                </div>

                <div className="form-control w-full mt-5">
                  <input
                    type="text"
                    {...register("tag")}
                    className="input input-bordered w-full "
                    placeholder="Blog Tag"
                  />
                </div>
              </div>

              <div className="form-control w-full mt-5">
                <textarea
                  type="textarea"
                  {...register("para1", {
                    required: "Paragraph1 is Required.",
                  })}
                  className="input input-bordered w-full h-40 p-3"
                  placeholder="Paragraph 1"
                />
                {errors.para1 && (
                  <p className="text-red-600" role="alert">
                    {errors.para1?.message}
                  </p>
                )}
              </div>

              <div className="form-control w-full mt-5">
                <textarea
                  type="textarea"
                  {...register("para2", {
                    required: "Paragraph1 is Required.",
                  })}
                  className="input input-bordered w-full h-40 p-3"
                  placeholder="Paragraph 2"
                />
              </div>

              <div className="form-control w-full mt-5">
                <textarea
                  type="textarea"
                  {...register("para3", {
                    required: "Paragraph1 is Required.",
                  })}
                  className="input input-bordered w-full h-40 p-3"
                  placeholder="Paragraph 3"
                />
              </div>

              <div className="form-control w-full mt-5">
                <textarea
                  type="textarea"
                  {...register("para4", {
                    required: "Paragraph1 is Required.",
                  })}
                  className="input input-bordered w-full h-40 p-3"
                  placeholder="Paragraph 4"
                />
              </div>

              <div className="form-control w-full mt-5">
                <textarea
                  type="textarea"
                  {...register("para5")}
                  className="input input-bordered w-full h-40 p-3"
                  placeholder="Paragraph 5"
                />
              </div>

              <div className="form-control w-full mt-5">
                <textarea
                  type="textarea"
                  {...register("para6")}
                  className="input input-bordered w-full h-40 p-3"
                  placeholder="Paragraph 6"
                />
              </div>

              <div className="form-control w-full mt-5">
                <textarea
                  type="textarea"
                  {...register("para7")}
                  className="input input-bordered w-full h-40 p-3"
                  placeholder="Paragraph 7"
                />
              </div>

              <div className="form-control w-full mt-5">
                <textarea
                  type="textarea"
                  {...register("para8")}
                  className="input input-bordered w-full h-40 p-3"
                  placeholder="Paragraph 8"
                />
              </div>

              <div className="form-control w-full mt-5">
                <textarea
                  type="textarea"
                  {...register("para9")}
                  className="input input-bordered w-full h-40 p-3"
                  placeholder="Paragraph 9"
                />
              </div>

              <div className="form-control w-full mt-5">
                <textarea
                  type="textarea"
                  {...register("para10")}
                  className="input input-bordered w-full h-40 p-3"
                  placeholder="Paragraph 10"
                />
              </div>

              <div className="form-control w-full mt-5">
                <textarea
                  type="textarea"
                  {...register("para11")}
                  className="input input-bordered w-full h-40 p-3"
                  placeholder="Paragraph 11"
                />
              </div>

              <div className="form-control w-full mt-5">
                <textarea
                  type="textarea"
                  {...register("para12")}
                  className="input input-bordered w-full h-40 p-3"
                  placeholder="Paragraph 12"
                />
              </div>

              <div className="form-control w-full mt-5">
                <input
                  type="text"
                  {...register("photoURL1", {
                    required: "Photo URL is Required.",
                  })}
                  className="input input-bordered w-full "
                  placeholder="Featured Photo URL"
                />
                {errors.photoURL && (
                  <p className="text-red-600" role="alert">
                    {errors.photoURL?.message}
                  </p>
                )}
              </div>
              <div className="form-control w-full mt-5">
                <input
                  type="text"
                  {...register("photoURL2", {
                    required: "Photo URL is Required.",
                  })}
                  className="input input-bordered w-full "
                  placeholder="Body Photo URL"
                />
                {errors.photoURL2 && (
                  <p className="text-red-600" role="alert">
                    {errors.photoURL2?.message}
                  </p>
                )}
              </div>

              <input
                className="btn btn-accent mt-5 text-white"
                value="Post"
                type="submit"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBlogs;
