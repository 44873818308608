import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../../context/AuthProvider";
import Loading from "../../../shared/Loading";
import toast from "react-hot-toast";
import { useQuery } from "@tanstack/react-query";

const AddCoachingStudents = () => {
  const d = new Date();
  let year = d.getFullYear();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const { loading } = useContext(AuthContext);

  const handleCourse = (data) => {
    saveData(data.name, data.class, data.year, data.note);
  };

  const saveData = (stName, stClass, year, note) => {
    const user = {
      stName,
      stClass,
      year,
      note,
    };
    fetch("https://server.arifur.xyz/addcoachingstudents", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(user),
    })
      .then((res) => res.json())
      .then((data) => {
        toast.success("Data Inserted Successfuly!");
        refetch();
        reset();
      });
  };

  const url = `https://server.arifur.xyz/coachingstudentlist`;
  const { data: students = [], refetch } = useQuery({
    queryKey: ["students"],
    queryFn: async () => {
      const res = await fetch(url, {
        headers: {
          authorization: `bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      const data = await res.json();
      return data;
    },
  });


  const handleDelete = (id) => {
    const agree = window.confirm(`Are you sure to delete the student?`);
    if (agree) {
      fetch(`https://server.arifur.xyz/coachingstudent/${id}`, {
        method: "DELETE",
        headers: {
          authorization: `bearer ${localStorage.getItem("accessToken")}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.deletedCount > 0) {
            toast.success("Student deleted successfully!");
            refetch();
          }
        });
    }
  };

  if (loading) {
    return <Loading></Loading>;
  }

  return (
    <div>
      <div className="py-4 lg:py-8">
        <div className="">
          <div onSubmit={handleSubmit} className="card w-full">
            <h2 className="text-center text-2xl font-semibold">
              Add a Student with Details
            </h2>
            <form onSubmit={handleSubmit(handleCourse)}>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
                <div className="form-control w-full mt-5">
                  <input
                    type="text"
                    {...register("name", {
                      required: "Student's Name is Required.",
                    })}
                    className="input input-bordered w-full "
                    placeholder="Student's Name"
                  />
                  {errors.name && (
                    <p className="text-red-600" role="alert">
                      {errors.name?.message}
                    </p>
                  )}
                </div>

                <div className="form-control w-full mt-5">
                  <select
                    {...register("class")}
                    className="input input-bordered w-full ">
                    <option>Select Class</option>
                    <option value="Six">Six</option>
                    <option value="Seven">Seven</option>
                    <option value="Eight">Eight</option>
                    <option value="Nine">Nine</option>
                    <option value="Ten">Ten</option>
                  </select>
                </div>
                <div className="form-control w-full mt-5">
                  <select
                    {...register("year")}
                    className="input input-bordered w-full ">
                    <option value={year}>{year}</option>
                  </select>
                </div>
              </div>
              <div className="form-control w-full mt-5">
                <textarea
                  type="text"
                  {...register("note")}
                  className="input input-bordered w-full h-20 p-3"
                  placeholder="Note"
                />
              </div>
              <input
                className="btn btn-accent mt-5 text-white"
                value="Add Student"
                type="submit"
              />
            </form>
          </div>
        </div>
        <div className="overflow-x-auto mt-10">
          <table className="table w-full">
            <thead>
              <tr>
                <th>SL</th>
                <th>Name</th>
                <th>Class</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {students.length && students?.map((student, i) => (
                <tr key={student._id}>
                  <th>{i + 1}</th>
                  <td>{student.stName}</td>
                  <td>{student.stClass}</td>
                  <td><button
                    onClick={() => handleDelete(student._id)}
                    className="btn btn-error btn-xs text-white">
                    Delete
                  </button></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AddCoachingStudents;
