import React, { useContext, useEffect, useState } from "react";
import Loading from "../../../shared/Loading";
import { AuthContext } from "../../../context/AuthProvider";
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";

const AddPayments = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const { loading } = useContext(AuthContext);
  const d = new Date();
  let year = d.getFullYear();

  let date = new Date();
  let currentDate = `${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()}`;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const url = `https://server.arifur.xyz/coachingstudentlist`;
  const { data: students = [] } = useQuery({
    queryKey: ["students"],
    queryFn: async () => {
      const res = await fetch(url, {
        headers: {
          authorization: `bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      const data = await res.json();
      return data;
    },
  });

  const url2 = `https://server.arifur.xyz/payments`;
  const { data: payments = [], refetch } = useQuery({
    queryKey: ["payments"],
    queryFn: async () => {
      const res = await fetch(url2, {
        headers: {
          authorization: `bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      const data = await res.json();
      return data;
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("https://server.arifur.xyz/payments");
      const data = await response.json();
      setData(data);
    };
    fetchData();
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleStudentList = (data) => {
    saveData(data.stName, data.month, data.year, data.amount);
  };

  const saveData = (stName, month, year, amount) => {
    const user = {
      stName,
      month,
      year,
      amount,
      date: currentDate,
    };
    fetch("https://server.arifur.xyz/postpayment", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(user),
    })
      .then((res) => res.json())
      .then((data) => {
        toast.success("Data Inserted Successfuly!");
        refetch();
        reset();
      });
  };

  const handleDelete = (id) => {
    const agree = window.confirm(`Are you sure to delete the student?`);
    if (agree) {
      fetch(`https://server.arifur.xyz/coachingpayment/${id}`, {
        method: "DELETE",
        headers: {
          authorization: `bearer ${localStorage.getItem("accessToken")}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.deletedCount > 0) {
            toast.success("Student deleted successfully!");
            refetch();
          }
        });
    }
  };

  if (loading) {
    return <Loading></Loading>;
  }

  return (
    <div>
      <div className="py-4 lg:py-8">
        <div className="">
          <div onSubmit={handleSubmit} className="card w-full">
            <h2 className="text-center text-2xl font-semibold">
              Add Students' Payment
            </h2>
            <form onSubmit={handleSubmit(handleStudentList)}>
              <div className="grid grid-cols-1 md:grid-cols-4 gap-3">
                <div className="form-control w-full mt-5">
                  <select
                    {...register("stName")}
                    className="input input-bordered w-full">
                    {students.length &&
                      students.map((student, i) => (
                        <option
                          key={i}
                          value={
                            student.stName + " / " + "Class: " + student.stClass
                          }>
                          {i + 1}.{" "}
                          {student.stName + " / " + "Class: " + student.stClass}
                        </option>
                      ))}
                  </select>
                </div>

                <div className="form-control w-full mt-5">
                  <select
                    {...register("month")}
                    className="input input-bordered w-full ">
                    <option>Select Month</option>
                    <option value="January">January</option>
                    <option value="February">February</option>
                    <option value="March">March</option>
                    <option value="April">April</option>
                    <option value="May">May</option>
                    <option value="June">June</option>
                    <option value="July">July</option>
                    <option value="August">August</option>
                    <option value="September">September</option>
                    <option value="October">October</option>
                    <option value="November">November</option>
                    <option value="December">December</option>
                  </select>
                </div>

                <div className="form-control w-full mt-5 hidden">
                  <select
                    {...register("year")}
                    className="input input-bordered w-full ">
                    <option value={year}>{year}</option>
                  </select>
                </div>

                <div className="form-control w-full mt-5">
                  <input
                    type="text"
                    {...register("amount", {
                      required: "Amount is Required.",
                    })}
                    className="input input-bordered w-full "
                    placeholder="Amount of Tk."
                  />
                  {errors.amount && (
                    <p className="text-red-600" role="alert">
                      {errors.amount?.message}
                    </p>
                  )}
                </div>
              </div>

              <input
                className="btn btn-accent mt-5 text-white"
                value="Add Payment"
                type="submit"
              />
            </form>
          </div>
        </div>
        <div className="overflow-x-auto mt-4">
          <p className="text-center font-semibold text-base mb-2">
            Total Collection:{" "}
            {payments.length &&
              payments
                ?.map((payment) => payment.amount)
                .reduce((a, b) => parseInt(a) + parseInt(b))}{" "}
            Tk in {year}
          </p>
          <table className="table w-full">
            <thead>
              <tr>
                <th>SL</th>
                <th>Name</th>
                <th>Amount</th>
                <th>Payment Date</th>
                <th>Month</th>
                <th>Year</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.length &&
                currentItems?.map((payment, i) => (
                  <tr key={payment._id}>
                    <th>{i + 1}</th>
                    <td>{payment.stName}</td>
                    <td>{payment.amount}</td>
                    <td>{payment.date}</td>
                    <td>{payment.month}</td>
                    <td>{payment.year}</td>
                    <td>
                      <button
                        onClick={() => handleDelete(payment._id)}
                        className="btn btn-error btn-xs text-white">
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>  
        </div>
        <div className="text-center text-white mt-3">
            {Array.from(
              { length: Math.ceil(data.length / itemsPerPage) },
              (_, i) => (
                <button
                  className="bg-accent px-2 py-1 mx-2"
                  key={i}
                  onClick={() => paginate(i + 1)}>
                  {i + 1}
                </button>
              )
            )}
          </div>
      </div>
    </div>
  );
};

export default AddPayments;
