import { useQuery } from "@tanstack/react-query";
import React from "react";
import { Link } from "react-router-dom";
import { FaLongArrowAltRight, FaMicroscope, FaEdit, FaBookmark } from "react-icons/fa";

const AllBlogsCard = ({blog}) => {


    const {_id, title, subject, category, tag, para1, photoURL1} = blog;
 

  const url = `https://server.arifur.xyz/mentors`;
  const { data: mentors = [] } = useQuery({
    queryKey: ["mentors"],
    queryFn: async () => {
      const res = await fetch(url, {
        headers: {
          authorization: `bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      const data = await res.json();
      return data;
    },
  });

  return (
    <div className="card card-compact bg-base-100 shadow">
      <figure>
        <img src={photoURL1} alt="" className="h-[250px]" />
      </figure>
      <div className="card-body">
        <h2 className="card-title font-anekBangla">{title}</h2>
        <p className="font-trioBangla">{para1?.slice(0, 200)} ...</p>

        {
          <div className="card-actions justify-between mt-3">
            <div className="flex">
              <img
                src={mentors[0]?.photoURL}
                alt=""
                className="w-16 h-16 rounded-full mr-2"></img>
              <div>
                <p className="font-oswald">Prepared by-</p>
                <p className="font-courgette">{mentors[0]?.name}</p>
                <p className="font-ubuntu">{mentors[0]?.designation}</p>
              </div>
            </div>
          </div>
        }
      </div>
      <div className="grid grid-cols-3 gap-2 mx-5 text-sm">
        <p className="icon"><FaMicroscope className="mr-2 text-fuchsia-700"/> {subject}</p>
        <p className="icon"><FaEdit className="mr-2 text-fuchsia-700"/> {category}</p> 
        <p className="icon"><FaBookmark className="mr-2 text-fuchsia-700"/> {tag}</p>
        </div>
      <div className="grid grid-cols-2 gap-2 mx-5">
        <p></p>
        <Link
          to={`/blogdetails/${_id}`}
          className="btn bg-white hover:bg-white text-black border-0 shadow-0 my-auto">
          Read More <FaLongArrowAltRight className="ml-3 text-lg" />
        </Link>
      </div>
    </div>
  );
};

export default AllBlogsCard;
