import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthProvider";
import { useLoaderData } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import "./../Courses/CardStyle.css";
import {
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import {
  FacebookIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import { FaHeart } from "react-icons/fa";
import toast from "react-hot-toast";

const Blogdetails = () => {
  const { user } = useContext(AuthContext);
  const blog = useLoaderData();

  const {
    _id,
    title,
    subject,
    category,
    tag,
    para1,
    para2,
    para3,
    para4,
    para5,
    para6,
    para7,
    para8,
    para9,
    para10,
    para11,
    para12,
    photoURL1,
    photoURL2,
  } = blog;

  const url = `https://server.arifur.xyz/mentors`;
  const { data: mentors = [] } = useQuery({
    queryKey: ["mentors"],
    queryFn: async () => {
      const res = await fetch(url, {
        headers: {
          authorization: `bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      const data = await res.json();
      return data;
    },
  });

  // bloglikecount/:blog

  const url_2 = `https://server.arifur.xyz/bloglikecount/${blog?._id}`;
  const { data: likes = [], refetch } = useQuery({
    queryKey: ["likes"],
    queryFn: async () => {
      const res = await fetch(url_2, {
        headers: {
          authorization: `bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      const data = await res.json();
      return data;
    },
  });

  const handleLike = (email, id) => {
    const myLikes = {
      email: email,
      blogID: id,
    };

    fetch(`https://server.arifur.xyz/postlikes/${email}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(myLikes),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.acknowledged) {
          toast.success("Your reaction counted successfuly!");
          refetch();
        } else {
          toast.error(data.message);
        }
      });
  };

  const handleLogin = () => {
    toast.error("Please Login to love this post.");
  };

  return (
    <div className="max-w-[1280px] mx-auto mt-10">
      <div>
        <div className="mx-5">
          <img
            src={photoURL1}
            alt=""
            className="rounded-2xl w-full shadow-lg"></img>
        </div>
      </div>
      <div className="mx-5 mt-10">
        <div className="">
          <div className="my-auto">
            <div className="">
              <h2 className="text-3xl my-3 font-anekBangla">{title}</h2>

              <div className="flex flex-row">
                <p className="my-3 font-oswald text-sm mr-3">
                  <span className="bg-blue-400 text-white px-2 py-1 rounded-lg">
                    {subject}
                  </span>
                </p>
                <p className=" my-3 font-oswald text-sm mr-3">
                  <span className="bg-violet-400 text-white px-2 py-1 rounded-lg">
                    {category}
                  </span>
                </p>
                <p className="my-3 font-oswald text-sm mr-3">
                  <span className="bg-rose-400 text-white px-2 py-1 rounded-lg">
                    {tag}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="font-trioBangla whitespace-pre-line">
            <p className="text-xl my-5 text-justify">{para1}</p>
            <p className="text-xl my-5 text-justify">{para2}</p>
            <p className="text-xl my-5 text-justify">{para3}</p>
            <p className="text-xl my-5 text-justify">{para4}</p>
            <p className="text-xl my-5 text-justify">{para5}</p>
            <p className="text-xl my-5 text-justify">{para6}</p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <img
                src={photoURL2}
                alt=""
                className="rounded-2xl w-full h-full my-auto"></img>
              <p className="text-xl my-5 text-justify">{para7}</p>
            </div>
            <p className="text-xl my-5 text-justify">{para8}</p>
            <p className="text-xl my-5 text-justify">{para9}</p>
            <p className="text-xl my-5 text-justify">{para10}</p>
            <p className="text-xl my-5 text-justify">{para11}</p>
            <p className="text-xl my-5 text-justify">{para12}</p>
          </div>
        </div>

        <div className="flex flex-row justify-between">
          <div>
            <FacebookShareButton url={`https://arifur.xyz/blogdetails/${_id}`}>
              <FacebookIcon className="rounded-full w-8 h-8 mr-2" />
            </FacebookShareButton>
            <TwitterShareButton
              url={`https://arifur.xyz/blogdetails/${_id}`}>
              <TwitterIcon className="rounded-full w-8 h-8 mr-2" />
            </TwitterShareButton>
            <WhatsappShareButton url={`https://arifur.xyz/blogdetails/${_id}`}>
              <WhatsappIcon className="rounded-full w-8 h-8 mr-2" />
            </WhatsappShareButton>
            <TelegramShareButton url={`https://arifur.xyz/blogdetails/${_id}`}>
              <TelegramIcon className="rounded-full w-8 h-8 mr-2" />
            </TelegramShareButton>
          </div>
          <div>
            {user ? (
              <div className="flex flex-row icon">
                <p onClick={() => handleLike(user?.email, blog?._id)}>
                  <FaHeart className="w-8 h-8 mr-2 text-rose-600" />
                </p>
                <p className="text-2xl font-semibold bg-blue-50 p-2">
                  {likes?.length}
                </p>
              </div>
            ) : (
              <div className="flex flex-row icon">
                <p onClick={() => handleLogin()}>
                  <FaHeart className="w-8 h-8 mr-2 text-rose-600" />
                </p>
                <p className="text-2xl font-semibold ">{likes?.length}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex my-10 mx-5">
        <img
          src={mentors[0]?.photoURL}
          alt=""
          className="w-20 h-20 rounded-full mr-2"></img>
        <div className="my-auto">
          <p className="text-xl font-acme">Prepared by-</p>
          <p className="font-courgette">{mentors[0]?.name}</p>
          <p className="font-ubuntu underline">{mentors[0]?.designation}</p>
          <p className="font-ubuntu">{mentors[0]?.phone}</p>
          <p className="font-ubuntu">{mentors[0]?.email}</p>
        </div>
      </div>
    </div>
  );
};

export default Blogdetails;
