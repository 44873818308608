import React, { useContext } from "react";
import Loading from "../../../shared/Loading";
import { AuthContext } from "../../../context/AuthProvider";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useQuery } from "@tanstack/react-query";

const AddCourseContents = () => {
  const url = `https://server.arifur.xyz/coursename`;
  const { data: courses = [] } = useQuery({
    queryKey: ["courses"],
    queryFn: async () => {
      const res = await fetch(url, {
        headers: {
          authorization: `bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      const data = await res.json();
      return data;
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { loading } = useContext(AuthContext);

  const handleCourse = (data) => {
    saveData(
      data.courseName,
      data.courseId,
      data.chapter,
      data.lesson1,
      data.lesson2,
      data.lesson3,
      data.lesson4,
      data.lesson5,
      data.lesson6
    );
  };

  const saveData = (
    courseName,
    courseId,
    chapter,
    lesson1,
    lesson2,
    lesson3,
    lesson4,
    lesson5,
    lesson6
  ) => {
    const user = {
      courseName,
      courseId,
      chapter,
      lesson1,
      lesson2,
      lesson3,
      lesson4,
      lesson5,
      lesson6,
    };

    fetch("https://server.arifur.xyz/postcourseoutline", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(user),
    })
      .then((res) => res.json())
      .then((data) => {});
    toast.success("Data Inserted Successfuly!");
    reset();
  };

  if (loading) {
    return <Loading></Loading>;
  }
  return (
    <div>
      <div className="py-4 lg:py-8">
        <div className="">
          <div onSubmit={handleSubmit} className="card w-full">
            <h2 className="text-center text-2xl font-semibold">
              Add Course Content
            </h2>
            <form onSubmit={handleSubmit(handleCourse)}>
              <div className="form-control w-full mt-5">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                  <div className="form-control w-full mt-5">
                    <select
                      {...register("courseName")}
                      className="input input-bordered w-full">
                      {courses.map((course, i) => (
                        <option key={i} value={course.courseName}>
                          {i + 1}. {course.courseName}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-control w-full mt-5">
                    <select
                      {...register("courseId")}
                      className="input input-bordered w-full">
                      {courses.map((course, i) => (
                        <option key={i} value={course._id}>
                          {i + 1}. {course._id}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <input
                  type="text"
                  {...register("chapter", {
                    required: "Chapter is Required.",
                  })}
                  className="input input-bordered w-full mt-5"
                  placeholder="Chapter"
                />
                {errors.chapter && (
                  <p className="text-red-600" role="alert">
                    {errors.chapter?.message}
                  </p>
                )}

                <div className="grid grid-cols-2 gap-3 mt-5">
                  <input
                    type="text"
                    {...register("lesson1", {
                      required: "Lesson is Required.",
                    })}
                    className="input input-bordered w-full"
                    placeholder="Lesson"
                  />
                  {errors.lesson1 && (
                    <p className="text-red-600" role="alert">
                      {errors.lesson1?.message}
                    </p>
                  )}

                  <input
                    type="text"
                    {...register("lesson2")}
                    className="input input-bordered w-full"
                    placeholder="Lesson"
                  />

                  <input
                    type="text"
                    {...register("lesson3")}
                    className="input input-bordered w-full"
                    placeholder="Lesson"
                  />

                  <input
                    type="text"
                    {...register("lesson4")}
                    className="input input-bordered w-full"
                    placeholder="Lesson"
                  />

                  <input
                    type="text"
                    {...register("lesson5")}
                    className="input input-bordered w-full"
                    placeholder="Lesson"
                  />

                  <input
                    type="text"
                    {...register("lesson6")}
                    className="input input-bordered w-full"
                    placeholder="Lesson"
                  />
                </div>
              </div>
              <input
                className="btn btn-accent mt-5 text-white"
                value="Post"
                type="submit"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCourseContents;
