import React from "react";
import AllProjectsCard from "./AllProjectsCard";
import { useQuery } from "@tanstack/react-query";

const Projects = () => {
  const url = `https://server.arifur.xyz/projects`;
  const { data: projects = [] } = useQuery({
    queryKey: ["projects"],
    queryFn: async () => {
      const res = await fetch(url, {
        headers: {
          authorization: `bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      const data = await res.json();
      return data;
    },
  });

  return (
    <div className="my-10 max-w-[1280px] mx-auto">
      <h2 className="text-center font-bold text-4xl my-10 font-ubuntu">
        My Portfolio
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mx-2 mb-40">
        {projects?.length &&
          projects.slice(0).reverse().map((project) => (
            <AllProjectsCard key={project._id} project={project}></AllProjectsCard>
          ))}
      </div>
    </div>
  );
};

export default Projects;
