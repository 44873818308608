import React from "react";
import { FaGithub, FaLink } from "react-icons/fa";
import { Link, useLoaderData } from "react-router-dom";
import {
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

const ProjectDetails = () => {
  const project = useLoaderData();

  const {
    _id,
    title,
    category,
    tag,
    technology,
    para1,
    para2,
    para3,
    feature1,
    feature2,
    feature3,
    githubLink,
    liveLink,
    photoURL1,
    photoURL2,
  } = project;

  return (
    <div className="max-w-[1280px] mx-auto mt-10">
      <div>
        <div className="mx-5">
          <img
            src={photoURL1}
            alt=""
            className="rounded-2xl w-full shadow-lg"></img>
        </div>
      </div>
      <div className="mx-5 mt-10">
        <div className="">
          <div className="my-auto">
            <div className="">
              <h2 className="text-3xl my-3 font-anekBangla">{title}</h2>
              <p className="my-3 font-oswald text-sm mr-3"> Technology used: {" "}  
                  <span className="underline">
                    {technology}
                  </span>
                </p>
              <div className="flex flex-row">
                <p className=" my-3 font-oswald text-sm mr-3"> 
                  <span className="bg-violet-400 text-white px-2 py-1 rounded-lg">
                    {category}
                  </span>
                </p>
                <p className="my-3 font-oswald text-sm mr-3">
                  <span className="bg-rose-400 text-white px-2 py-1 rounded-lg">
                    {tag}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="font-trioBangla whitespace-pre-line">
            <p className="text-xl my-5 text-justify">{para1}</p>
            <p className="text-xl my-5 text-justify">{para2}</p>
            <p className="text-xl my-5 text-justify">{para3}</p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <img
                src={photoURL2}
                alt=""
                className="rounded-2xl w-full h-full my-auto"></img>
              <div>
                <p className="text-xl my-5 text-justify">Features:</p>
                <p className="text-xl my-5 text-justify list-item list-inside list-disc">{feature1}</p>
                <p className="text-xl my-5 text-justify list-item list-inside list-disc">{feature2}</p>
                <p className="text-xl my-5 text-justify list-item list-inside list-disc">{feature3}</p>
                <p className="text-xl my-5 text-justify icon"><FaGithub className="mr-3"/> <Link to={githubLink} target="_blank" className="underline text-blue-700">{githubLink}</Link></p>
                <p className="text-xl my-5 text-justify icon"><FaLink className="mr-3"/> <Link to={liveLink} target="_blank" className="underline text-blue-700">{liveLink}</Link></p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-row justify-between my-10">
          <div>
            <FacebookShareButton url={`https://arifur.xyz/project/${_id}`}>
              <FacebookIcon className="rounded-full w-8 h-8 mr-2" />
            </FacebookShareButton>
            <TwitterShareButton url={`https://arifur.xyz/project/${_id}`}>
              <TwitterIcon className="rounded-full w-8 h-8 mr-2" />
            </TwitterShareButton>
            <WhatsappShareButton url={`https://arifur.xyz/project/${_id}`}>
              <WhatsappIcon className="rounded-full w-8 h-8 mr-2" />
            </WhatsappShareButton>
            <TelegramShareButton url={`https://arifur.xyz/project/${_id}`}>
              <TelegramIcon className="rounded-full w-8 h-8 mr-2" />
            </TelegramShareButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;
