import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey:"AIzaSyA3Tc6n0rr4-CnCh6FLLav8Hg2rkIKcJUQ",
authDomain:"arifurxyz-4d199.firebaseapp.com",
projectId:"arifurxyz-4d199",
storageBucket:"arifurxyz-4d199.appspot.com",
messagingSenderId:"11672655192",
appId:"1:11672655192:web:5cd97037be64c2ba49812d"
};


const app = initializeApp(firebaseConfig);

export default app;