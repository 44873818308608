import React from "react";
import "../Courses/CardStyle.css"
import { FaRegCheckCircle } from "react-icons/fa";

const CourseContents = ({ content }) => {
  const { chapter, lesson1, lesson2, lesson3, lesson4, lesson5, lesson6 } =
    content;

  return (
    <div>
      <div className="collapse collapse-open mt-3">
        <div className="collapse-title text-xl font-medium bg-base-300">
          {chapter}
        </div>
        <div className="collapse-content bg-base-200 pt-1 px-8 font-signika text-lg list-none">
          {lesson1 === "" ? <p>{}</p> : <li className="icon"><FaRegCheckCircle className="mr-2 text-primary"/>{lesson1}</li>}
          {lesson2 === "" ? <p>{}</p> : <li className="icon"><FaRegCheckCircle className="mr-2 text-primary"/>{lesson2}</li>}
          {lesson3 === "" ? <p>{}</p> : <li className="icon"><FaRegCheckCircle className="mr-2 text-primary"/>{lesson3}</li>}
          {lesson4 === "" ? <p>{}</p> : <li className="icon"><FaRegCheckCircle className="mr-2 text-primary"/>{lesson4}</li>}
          {lesson5 === "" ? <p>{}</p> : <li className="icon"><FaRegCheckCircle className="mr-2 text-primary"/>{lesson5}</li>}
          {lesson6 === "" ? <p>{}</p> : <li className="icon"><FaRegCheckCircle className="mr-2 text-primary"/>{lesson6}</li>}
        </div>
      </div>
    </div>
  );
};

export default CourseContents;
