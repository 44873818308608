import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

const AddBlogs = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleCourse = (data) => {
    saveData(
      data.title,
      data.category,
      data.tag,
      data.technology,
      data.para1,
      data.para2,
      data.para3,
      data.feature1,
      data.feature2,
      data.feature3,
      data.githubLink,
      data.liveLink,
      data.photoURL1,
      data.photoURL2
    );
  };

  const saveData = (
    title,
    category,
    tag,
    technology,
    para1,
    para2,
    para3,
    feature1,
    feature2,
    feature3,
    githubLink,
    liveLink,
    photoURL1,
    photoURL2
  ) => {
    const user = {
      title,
      technology,
      category,
      tag,
      para1,
      para2,
      para3,
      feature1,
      feature2,
      feature3,
      githubLink,
      liveLink,
      photoURL1,
      photoURL2,
    };
    fetch("https://server.arifur.xyz/postprojects", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(user),
    })
      .then((res) => res.json())
      .then((data) => {
        toast.success("Data Inserted Successfuly!");
        reset();
      });
  };

  const url = `https://server.arifur.xyz/projects`;
  const { data: projects = [], refetch } = useQuery({
    queryKey: ["projects"],
    queryFn: async () => {
      const res = await fetch(url, {
        headers: {
          authorization: `bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      const data = await res.json();
      return data;
    },
  });

  const handleDelete = (id) => {
    const agree = window.confirm(`Are you sure to delete the Project?`);
    if (agree) {
      fetch(`https://server.arifur.xyz/project/${id}`, {
        method: "DELETE",
        headers: {
          authorization: `bearer ${localStorage.getItem("accessToken")}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.deletedCount > 0) {
            toast.success("Project deleted successfully!");
            refetch();
          }
        });
    }
  };

  return (
    <div>
      <div className="py-4 lg:py-8">
        <div className="">
          <div onSubmit={handleSubmit} className="card w-full">
            <h2 className="text-center text-2xl font-semibold">
              Add a Project
            </h2>
            <form onSubmit={handleSubmit(handleCourse)}>
              <div className="grid grid-cols-1 md:grid-cols-4 gap-3">
                <div className="form-control w-full mt-5 md:col-span-2">
                  <input
                    type="text"
                    {...register("title")}
                    className="input input-bordered w-full "
                    placeholder="Project Title"
                  />
                </div>
                <div className="form-control w-full mt-5">
                  <input
                    type="text"
                    {...register("category")}
                    className="input input-bordered w-full "
                    placeholder="Project Category"
                  />
                </div>

                <div className="form-control w-full mt-5">
                  <input
                    type="text"
                    {...register("tag")}
                    className="input input-bordered w-full "
                    placeholder="Project Tag"
                  />
                </div>
              </div>

              <div className="form-control w-full mt-5">
                <input
                  type="text"
                  {...register("technology")}
                  className="input input-bordered w-full p-3"
                  placeholder="Technology Used"
                />
              </div>

              <div className="form-control w-full mt-5">
                <textarea
                  type="textarea"
                  {...register("para1", {
                    required: "Paragraph1 is Required.",
                  })}
                  className="input input-bordered w-full h-40 p-3"
                  placeholder="Paragraph 1"
                />
                {errors.para1 && (
                  <p className="text-red-600" role="alert">
                    {errors.para1?.message}
                  </p>
                )}
              </div>

              <div className="form-control w-full mt-5">
                <textarea
                  type="textarea"
                  {...register("para2", {
                    required: "Paragraph1 is Required.",
                  })}
                  className="input input-bordered w-full h-40 p-3"
                  placeholder="Paragraph 2"
                />
              </div>

              <div className="form-control w-full mt-5">
                <textarea
                  type="textarea"
                  {...register("para3", {
                    required: "Paragraph1 is Required.",
                  })}
                  className="input input-bordered w-full h-40 p-3"
                  placeholder="Paragraph 3"
                />
              </div>

              <div className="form-control w-full mt-5">
                <input
                  type="text"
                  {...register("feature1")}
                  className="input input-bordered w-full p-3"
                  placeholder="Feature"
                />
              </div>

              <div className="form-control w-full mt-5">
                <input
                  type="text"
                  {...register("feature2")}
                  className="input input-bordered w-full p-3"
                  placeholder="Feature"
                />
              </div>

              <div className="form-control w-full mt-5">
                <input
                  type="text"
                  {...register("feature3")}
                  className="input input-bordered w-full p-3"
                  placeholder="Feature"
                />
              </div>

              <div className="form-control w-full mt-5">
                <input
                  type="text"
                  {...register("githubLink")}
                  className="input input-bordered w-full "
                  placeholder="Github URL"
                />
              </div>

              <div className="form-control w-full mt-5">
                <input
                  type="text"
                  {...register("liveLink")}
                  className="input input-bordered w-full "
                  placeholder="Live URL"
                />
              </div>

              <div className="form-control w-full mt-5">
                <input
                  type="text"
                  {...register("photoURL1", {
                    required: "Photo URL is Required.",
                  })}
                  className="input input-bordered w-full "
                  placeholder="Featured Photo URL"
                />
                {errors.photoURL && (
                  <p className="text-red-600" role="alert">
                    {errors.photoURL?.message}
                  </p>
                )}
              </div>

              <div className="form-control w-full mt-5">
                <input
                  type="text"
                  {...register("photoURL2", {
                    required: "Photo URL is Required.",
                  })}
                  className="input input-bordered w-full "
                  placeholder="Body Photo URL"
                />
                {errors.photoURL2 && (
                  <p className="text-red-600" role="alert">
                    {errors.photoURL2?.message}
                  </p>
                )}
              </div>

              <input
                className="btn btn-accent mt-5 text-white"
                value="Post"
                type="submit"
              />
            </form>
          </div>
        </div>
        <div className="overflow-x-auto">
          <h2 className="text-center text-xl font-semibold my-3">Project List</h2>
        <table className="table w-full">
          <thead>
            <tr>
              <th>SL</th>
              <th>Name</th>
              <th>Technology</th>
              <th>Category</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {projects &&
                projects?.map((project, i) => (
                  <tr key={project._id}>
                    <th>{i + 1}</th>
                    <td>{project.title}</td>
                    <td>{project.technology}</td>
                    <td>{project.category}</td>
                    <td>
                      <button
                        onClick={() => handleDelete(project._id)}
                        className="btn btn-error btn-xs text-white">
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
      </div>
    </div>
  );
};

export default AddBlogs;
