import React from "react";
import { FaBookmark, FaEdit, FaLongArrowAltRight, FaMicroscope } from "react-icons/fa";
import { Link } from "react-router-dom";

const AllProjectsCard = ( {project} ) => {
  const { _id, title, category, tag, technology, para1, photoURL1 } = project;

  return (
    <div className="card card-compact bg-base-100 shadow">
      <figure>
        <Link to={`/project/${_id}`}><img src={photoURL1} alt="" className="h-[250px]" /></Link>
      </figure>
      <div className="card-body">
        <Link to={`/project/${_id}`}><h2 className="card-title font-anekBangla">{title}</h2></Link>
        <p className="font-trioBangla">{para1?.slice(0, 200)} ...</p>
      </div>
      <div className="grid grid-cols-3 gap-2 mx-5 text-sm">
        <p className="icon">
          <FaMicroscope className="mr-2 text-fuchsia-700" /> {technology?.slice(0, 15)}
        </p>
        <p className="icon">
          <FaEdit className="mr-2 text-fuchsia-700" /> {category}
        </p>
        <p className="icon">
          <FaBookmark className="mr-2 text-fuchsia-700" /> {tag}
        </p>
      </div>
      <div className="grid grid-cols-2 gap-2 mx-5">
        <p></p>
        <Link
          to={`/project/${_id}`}
          className="btn bg-white hover:bg-white text-black border-0 shadow-0 my-auto">
          Read More <FaLongArrowAltRight className="ml-3 text-lg" />
        </Link>
      </div>
    </div>
  );
};

export default AllProjectsCard;
