import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

const MonthlyPayment = () => {
  const [loader, setLoader] = useState(false);
  const downloadPDF = () => {
    const capture = document.querySelector(".dataTable");
    setLoader(true);
    html2canvas(capture).then((canvas) => {
      const imgData = canvas.toDataURL("img/png");
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: [297, 210],
      });
      doc.addImage(imgData, "PNG", 5, 5);
      doc.save("monthlyReport.pdf");
      setLoader(false);
    });
  };

  const { register, handleSubmit } = useForm();
  const [searchData, setSearchData] = useState();

  const handleMonthlyPayment = (data) => {
    const url = `https://server.arifur.xyz/monthlycollections/${data?.month}`;
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setSearchData(data);
      });
  };

  return (
    <div className="max-w-[780px] mx-auto">
      <div>
        <p className="text-2xl font-semibold text-center mb-5">
          Monthly Payment View
        </p>
        <form onSubmit={handleSubmit(handleMonthlyPayment)}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
            <div className="form-control w-full mt-5">
              <select
                {...register("month")}
                className="input input-bordered w-full ">
                <option>Select Month</option>
                <option value="January">January</option>
                <option value="February">February</option>
                <option value="March">March</option>
                <option value="April">April</option>
                <option value="May">May</option>
                <option value="June">June</option>
                <option value="July">July</option>
                <option value="August">August</option>
                <option value="September">September</option>
                <option value="October">October</option>
                <option value="November">November</option>
                <option value="December">December</option>
              </select>
            </div>
            <input
              className="btn btn-accent mt-5 text-white"
              value="View Payment History"
              type="submit"
            />
          </div>
        </form>
      </div>
      <div className="overflow-x-auto mt-4">
        <button
          className="btn btn-xs mb-2"
          onClick={downloadPDF}
          disabled={!(loader === false)}>
          {loader ? <span>Downloading</span> : <span>Download</span>}
        </button>
        <table className="table dataTable md:w-[780px]">
          <thead>
            <tr>
              <th className="py-2">SL</th>
              <th className="py-2">Name</th>
              <th className="py-2">Amount</th>
              <th className="py-2">Payment Date</th>
              <th className="py-2">Month</th>
              <th className="py-2">Year</th>
            </tr>
          </thead>
          <tbody>
            {searchData?.length &&
              searchData?.map((payment, i) => (
                <tr key={payment._id}>
                  <th className="text-base py-1">{i + 1}</th>
                  <td className="text-base py-1">{payment.stName}</td>
                  <td className="text-base py-1">{payment.amount}</td>
                  <td className="text-base py-1">{payment.date}</td>
                  <td className="text-base py-1">{payment.month}</td>
                  <td className="text-base py-1">{payment.year}</td>
                </tr>
              ))}
            <tr>
              <td colSpan={2}>
                <p className="text-end font-semibold text-base">Total:</p>
              </td>
              <td>
                {searchData?.length &&
                  searchData
                    ?.map((payment) => payment.amount)
                    .reduce((a, b) => parseInt(a) + parseInt(b))}{" "}
                Tk
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MonthlyPayment;
